import React from "react";

export const Role = (props) => {
  return (
    <div className="w-full border-t">
      <div className="w-full flex justify-between items-center">
        {" "}
        <h3 className="text-lg font-bold">{props.title}</h3>
        <p>{props.date}</p>
      </div>
      <p>{props.location}</p>
      <ul className="text-gray-500 list-disc ml-4">
        {props.details &&
          props.details.map((item, idx) => {
            return <li key={idx}>{item}</li>;
          })}
      </ul>
    </div>
  );
};
